import {lazy, Suspense} from "react";
// components
import LoadingScreen from "../components/loading-screen";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<LoadingScreen/>}>
            <Component {...props} />
        </Suspense>
    );

// ----------------------------------------------------------------------

// AUTHENTICATION
export const Login = Loadable(lazy(() => import("../pages/auth/LoginPage")));

// Compensation
export const CompensationPage = Loadable(lazy(() => import("../pages/Compensation")));
export const CompensationAddRemovePage = Loadable(lazy(() => import("../pages/Compensation/CompensationAddRemove")));

//PAGES
export const HomePage = Loadable(lazy(() => import("../pages/Home")));
export const CreateOrderPage = Loadable(lazy(() => import("../pages/Home/OrderPage")));
export const AnalyticPage = Loadable(lazy(() => import("../pages/Analytics")));
export const SettingsPage = Loadable(lazy(() => import("../pages/Settings")));
//RACUNI
export const InvoicesPage = Loadable(lazy(() => import("../pages/Invoices")));

//IZVESTAJ
export const OverviewPage = Loadable(lazy(() => import("../pages/Reports/Overview")));
export const ItemsReportPage = Loadable(lazy(() => import("../pages/Reports/Items/ItemsReportPage")));
export const CostsReportPage = Loadable(lazy(() => import("../pages/Reports/Costs")));
export const WriteOffReportPage = Loadable(lazy(() => import("../pages/Reports/WriteOff")));
export const DPUReportPage = Loadable(lazy(() => import("../pages/Reports/DPU")));
export const ActiveOrdersReportPage = Loadable(lazy(() => import("../pages/Reports/ActiveOrders")));
export const FilterReportPage = Loadable(lazy(() => import("../pages/Reports/Filter")));
export const ProfitReportPage = Loadable(lazy(() => import("../pages/Reports/Profit")));
export const TableReportPage = Loadable(lazy(() => import("../pages/Reports/Table")));
export const TurnoverWithoutCompensationPage = Loadable(lazy(() => import("../pages/Reports/TurnoverWithoutCompensationPage")));
export const ItemsCardPage = Loadable(lazy(() => import("../pages/Reports/ItemsCard")));
export const RepresentationPage = Loadable(lazy(() => import("../pages/Reports/Representation")));
export const DeleteOrdersPage = Loadable(lazy(() => import("../pages/Reports/DeletedOrders")));

export const SuppliersPage = Loadable(lazy(() => import("../pages/Warehouse/Suppliers")));
export const SuppliersCreatePage = Loadable(lazy(() => import("../pages/Warehouse/Suppliers/SuppliersCreatePage")));
export const SuppliersEditPage = Loadable(lazy(() => import("../pages/Warehouse/Suppliers/SuppliersEditPage")));
export const FacturesPage = Loadable(lazy(() => import("../pages/Warehouse/Factures")));
export const FacturePreview = Loadable(lazy(() => import("../pages/Warehouse/Factures/FacturePreview")));
export const FactureAdditionalPayment = Loadable(lazy(() => import("../pages/Warehouse/Factures/FactureAdditionalPayment")));
export const FactureCreatePage = Loadable(lazy(() => import("../pages/Warehouse/Factures/FactureCreatePage")));
export const FactureEditPage = Loadable(lazy(() => import("../pages/Warehouse/Factures/FactureEditPage")));
export const StockListPage = Loadable(lazy(() => import("../pages/Warehouse/StockList")));
export const IngredientsPage = Loadable(lazy(() => import("../pages/Warehouse/Ingredients")));
export const IngredientEditPage = Loadable(lazy(() => import("../pages/Warehouse/Ingredients/IngredientEditPage")));
export const IngredientCreatePage = Loadable(lazy(() => import("../pages/Warehouse/Ingredients/IngredientCreatePage")));
export const InventoryPage = Loadable(lazy(() => import("../pages/Warehouse/Inventory")));
export const InventoryPreviewPage = Loadable(lazy(() => import("../pages/Warehouse/Inventory/InventoryPreviewPage")));
export const WriteOffPage = Loadable(lazy(() => import("../pages/Warehouse/WriteOff")));
export const WriteOffCreatePage = Loadable(lazy(() => import("../pages/Warehouse/WriteOff/WriteOffCreatePage")));

export const ClientsPage = Loadable(lazy(() => import("../pages/Warehouse/Clients")));
export const ClientPreviewPage = Loadable(lazy(() => import("../pages/Warehouse/Clients/ClientPreview")));

// ITEMS
export const ItemsPage = Loadable(lazy(() => import("../pages/Items")));
export const ItemsNewPage = Loadable(lazy(() => import('../pages/Items/ItemsNew')));
export const ItemsEditPage = Loadable(lazy(() => import('../pages/Items/ItemsEdit')));

export const StartersItemsPage = Loadable(lazy(() => import('../pages/Items/StarterItems')));

// OPERATORS
export const OperatorsList = Loadable(lazy(() => import('../pages/Operators/OperatorsList')))
export const OperatorsNew = Loadable(lazy(() => import('../pages/Operators/OperatorsNew')))
export const OperatorsEditPage = Loadable(lazy(() => import('../pages/Operators/OperatorsEditPage')))

// CUSTOMERS
export const CustomersList = Loadable(lazy(() => import('../pages/Customers/CustomersList')))
export const CustomersNew = Loadable(lazy(() => import('../pages/Customers/CustomersNew')))
export const CustomersEditPage = Loadable(lazy(() => import('../pages/Customers/CustomersEditPage')))

// CUSTOMERS
export const CostsList = Loadable(lazy(() => import('../pages/Costs/CostsList')))
export const CostsNew = Loadable(lazy(() => import('../pages/Costs/CostsNew')))
export const CostsEditPage = Loadable(lazy(() => import('../pages/Costs/CostsEditPage')))

//DISCOUNTS
export const DiscountsList = Loadable(lazy(() => import('../pages/Discounts/DiscountList')));
export const DiscountsNew = Loadable(lazy(() => import('../pages/Discounts/DiscountsNew')));
export const DiscountsEditPage = Loadable(lazy(() => import('../pages/Discounts/DiscountEditPage')));
export const DiscountsUsersList = Loadable(lazy(() => import('../pages/Discounts/DiscountUsersList')));
export const DiscountsUserNew = Loadable(lazy(() => import('../pages/Discounts/CustomerDiscountNew')));
export const DiscountUserEditPage = Loadable(lazy(() => import('../pages/Discounts/CustomerDiscountEditPage')));

// OTHERS
export const MaintenancePage = Loadable(lazy(() => import("../pages/Maintenance")));

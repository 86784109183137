import PropTypes from "prop-types";
// @mui
import {Alert, Box, Button, Card, IconButton, InputAdornment, Link, Stack, Typography} from "@mui/material";
import Iconify from "../../components/Iconify";
import React, {useEffect, useRef, useState} from "react";
import {dispatch, useSelector} from "../../redux/store";
import {fetchAllOperators} from "../../redux/slices/operators";
import {useAuthContext} from "../../auth/useAuthContext";
import {useSettingsContext} from "../../components/settings";
import {useSnackbar} from "notistack";
import FormProvider, {RHFTextField} from "../../components/hook-form";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {LoadingButton} from "@mui/lab";
import {PATH_AUTH, PATH_DASHBOARD} from "../../routes/path";
import {useNavigate} from "react-router-dom";
import {getAllPublicGroups} from "../../redux/slices/groupsPublic";
//
import {getSessionTheme} from "../../helper/session";
import Keyboard from "react-simple-keyboard";
import {setOperatorInfo} from "../../redux/slices/user";
import "react-simple-keyboard/build/css/index.css";
import "./keyboard.css";
import LoginLayout from "./LoginLayout";
// ----------------------------------------------------------------------

const KEYBOARDS = {
    0: {
        default: [
            "1 2 3",
            "4 5 6",
            "7 8 9",
            "{shift} 0 BRISANJE",
        ],
        shift: ["! / #",
            "$ % ^",
            "& * (",
            "{shift} ) +",
            "BRISANJE"
        ]
    }
};

const keyboard_layout_name = {
    default: "default",
    shift: "shift"
};

OperatorLayout.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    illustration: PropTypes.string
};

export default function OperatorLayout() {
    const navigate = useNavigate();

    const {enqueueSnackbar} = useSnackbar();

    const {logout} = useAuthContext();

    const {groups} = useSelector(state => state.groupsPublic);
    const {allOperators} = useSelector(state => state.operators);

    useEffect(() => {
        dispatch(fetchAllOperators());
        dispatch(getAllPublicGroups());
    }, []);


    const handleLogout = async () => {
        try {
            logout();
            navigate(PATH_AUTH.login, {replace: true});
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Došlo je do greške!", {variant: "error"});
        }
    };


    return (
        <LoginLayout>
            {allOperators.length === 0 ?
                <Box sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flex: 1,
                    width: "100hw",
                    height: "100vh"
                }}>
                    <Typography variant={"h5"}>Operateri nisu pronađeni...</Typography>
                </Box> :
                <Box sx={{
                    gap: 5,
                    display: 'flex',
                    flex: 1,
                    height: '100vh',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Typography textAlign="center" variant="h4" color="dimgray">
                        Prijavljivanje operater na rad</Typography>
                    <OperatorCard
                        allOperators={allOperators}
                        groups={groups}
                        enqueueSnackbar={enqueueSnackbar}
                        navigate={navigate}/>
                </Box>
            }
            <Button onClick={handleLogout}
                    sx={{position: "fixed", bottom: 25, right: 25}} variant={"contained"}>Odjavi se</Button>

        </LoginLayout>
    );
}

const OperatorCard = ({allOperators, groups, enqueueSnackbar, navigate, handleFocus}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [keyboardLayout, setKeyboardLayout] = useState(keyboard_layout_name.default);
    const OperatorLoginSchema = Yup.object().shape({
        password: Yup.string().required("Lozinka je obavezna!")
    });
    const [showKeyboard, setShowKeyboard] = useState(false);
    const defaultValues = {
        password: ""
    };

    const methods = useForm({
        resolver: yupResolver(OperatorLoginSchema),
        defaultValues
    });

    const {
        reset,
        setError,
        setValue,
        handleSubmit,
        watch,
        formState: {errors, isSubmitting}
    } = methods;

    const findRoleName = (roleId) => {
        return groups?.find(g => g.uid === roleId)?.name;
    };
    const inputRef = useRef();

    const values = watch();

    const onSubmit = async (props) => {
        const operatorFound = allOperators?.find(o =>
            o?.secret?.toString() === props.password || o?.cardNumber?.toString() === props.password
        );

        try {
            if (operatorFound) {
                dispatch(setOperatorInfo(operatorFound));
                return navigate(PATH_DASHBOARD.general.app, {replace: true});
            }

            enqueueSnackbar("Lozinka nije validna!", {variant: "error"});
        } catch (error) {
            console.error(error);
            reset();
            setError("afterSubmit", {
                ...error,
                message: "Uneti kredencijali nisu validni!"
            });
        }
    };


    const localOnKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") {
            setKeyboardLayout(prevState => prevState === keyboard_layout_name.default ?
                keyboard_layout_name.shift : keyboard_layout_name.default);
            return;
        }
        onKeyPress(button);
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") {
            setKeyboardLayout(prevLayout => (prevLayout === "default" ? "shift" : "default"));
        } else {
            const value = inputRef.current.value;
            if (button === "BRISANJE") {
                inputRef.current.value = value.slice(0, -1);
            } else if (button === "{space}") {
                inputRef.current.value = value + " ";
            } else {
                inputRef.current.value = value + button;
            }
            setValue("password", inputRef.current.value);
        }
    };


    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <Card
                sx={{
                    minWidth: 400,
                    py: 5,
                    px: 5,
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    flexDirection: "column"
                }}
            >

                <RHFTextField
                    name="password"
                    label="Lozinka"
                    type={showPassword ? "text" : "password"}
                    inputRef={inputRef}
                    sx={{mt: 2}}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                <LoadingButton
                    fullWidth
                    color="inherit"
                    size="small"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{
                        mt: 1,
                        bgcolor: "text.primary",
                        color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800"),
                        "&:hover": {
                            bgcolor: "text.primary",
                            color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800")
                        }
                    }}
                >
                    Prijavi se
                </LoadingButton>

            </Card>
            <Stack sx={{display: 'flex', alignItems: 'center'}}>
                <Keyboard
                    theme={getSessionTheme() === "dark" ? "hg-theme-default dark" : "hg-theme-default"}
                    onKeyPress={button => localOnKeyPress(button)}
                    layoutName={keyboardLayout}
                    layout={{
                        default: KEYBOARDS[0].default,
                        shift: KEYBOARDS[0].shift
                    }}
                    buttonTheme={[
                        {
                            class: "hg-red",
                            buttons: "BRISANJE"
                        },
                    ]}
                />
            </Stack>

        </FormProvider>
    );
};

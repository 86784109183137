import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {itemsAveragePurchasePrice} from "../../helper/realtimeDatabase";
import moment from "moment";
import {child, get} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {getAllFacturesByDateFromFirestore} from "../../helper/firestore/factures";
import {getNewReports} from "../../api/vpfr";
import {parseNewReport} from "../../helper/report/newReport";
import {calculateBasicOfItem, calculateMarkUp} from "../../helper/other";

export const fetchStockList = createAsyncThunk("fetchStockList", async (data, {getState}) => {
    const state = getState();
    const {currentTaxRates} = state.taxCore;
    const {allItemsList} = state.items;
    const {chosenDate} = data;
    let items = {}
    allItemsList.forEach(item => {
        items[item.uid] = item
    });
    let ingredients = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients`))).val();
    if(!ingredients) {
        ingredients = {}
    }
    //entryQuantity
    const factures = await getAllFacturesByDateFromFirestore(
        moment(chosenDate).set("hour", 0).set("minute", 0).set("second", 0).toDate(),
        moment(chosenDate).set("hour", 23).set("minute", 59).set("second", 59).toDate());
    for (const facture of factures) {
        for (const fItem of [...facture.items, ...facture.costs, ...(facture?.ingredients || [])]) {
            if (items[fItem.uid]) {
                if (items[fItem.uid].entryQuantity !== undefined) {
                    items[fItem.uid].entryQuantity += Number(fItem.quantity)
                } else {
                    items[fItem.uid] = {
                        ...items[fItem.uid],
                        entryQuantity: Number(fItem.quantity)
                    }
                }
            }
            if (ingredients[fItem.uid]) {
                if (ingredients[fItem.uid].entryQuantity !== undefined) {
                    ingredients[fItem.uid].entryQuantity += Number(fItem.quantity)
                } else {
                    ingredients[fItem.uid] = {
                        ...ingredients[fItem.uid],
                        entryQuantity: Number(fItem.quantity)
                    }
                }
            }
        }
    }
    const dateFrom = moment(chosenDate).format("YYYY-MM-DD");
    const dateTo = moment(chosenDate).add(1, "day").format("YYYY-MM-DD");
    const result = (await getNewReports(dateFrom, dateTo)).data;
    const report = parseNewReport(result.derived);
    for (const sItem of report.itemsSale) {
        if (sItem.uid) {
            for (const uid of Object.keys(sItem.uid)) {
                if (items[uid]) {
                    const itemIng = Object.keys(items[uid].ingredients || {});
                    if (itemIng.length > 0) {
                        for (const ingKey of itemIng) {
                            if (ingredients[ingKey]) {
                                const needForMake = Number(items[uid].ingredients[ingKey].quantity);
                                const soldQty = Number(sItem.uid[uid]);
                                if (ingredients[ingKey].exitQuantity !== undefined) {
                                    ingredients[ingKey].exitQuantity += Number(needForMake * soldQty)
                                } else {
                                    ingredients[ingKey] = {
                                        ...ingredients[ingKey],
                                        exitQuantity: Number(needForMake * soldQty)
                                    }
                                }
                            }
                        }
                    } else {
                        if (items[uid].exitQuantity !== undefined) {
                            items[uid].exitQuantity += Number(sItem.uid[uid])
                        } else {
                            items[uid] = {
                                ...items[uid],
                                exitQuantity: Number(sItem.uid[uid])
                            }
                        }
                    }
                }
            }
        }
    }
    let allItems = [];
    Object.keys(items).forEach(key => {
        const item = items[key];
        if (Object.keys(item?.ingredients || {}).length === 0) {
            allItems.push({
                ...item,
                type: "Proizvod"
            });
        }
    })
    Object.keys(ingredients).forEach(key => {
        allItems.push({
            ...ingredients[key],
            type: "Sastojak"
        });
    })
    //currentItemsState
    //TODO ovo ce trebati kada napravimo mesecne izvestaje
    // let currentItemsState = await itemsStateOnDate(chosenDate);
    //average purache price
    let avgItemsPrice = await itemsAveragePurchasePrice(chosenDate);
    allItems = allItems.map(item => {
        const price = item?.price || 0;
        let exitQuantity = item?.exitQuantity || 0;
        let entryQuantity = item?.entryQuantity || 0;
        // Koristimo stanje koje se nalazi na proizvodu
        let avgPurchasePrice = avgItemsPrice[item.uid] || 0;
        let avgPurchasePriceWithoutTax = calculateBasicOfItem({
            quantity: 1,
            unitPrice: avgPurchasePrice,
            vat: item.vat
        }, currentTaxRates);
        let priceWithoutTax = calculateBasicOfItem({
            quantity: 1,
            unitPrice: item.price,
            vat: item.vat
        }, currentTaxRates);
        let restQuantity = Number(item.quantity);
        let startQuantity = restQuantity - entryQuantity + exitQuantity;
        return {
            ...item,
            id: item.uid,
            startQuantity: Number(startQuantity).toFixed(2),
            entryQuantity: Number(entryQuantity).toFixed(2),
            exitQuantity: Number(exitQuantity).toFixed(2),
            restQuantity: Number(restQuantity).toFixed(2),
            marza: (item.price ? Number(calculateMarkUp(avgPurchasePrice, price)).toFixed(2) : 0) + "%",
            ruc: (item.price ? Number(item.price - avgPurchasePrice).toFixed(2) : 0),
            avgPurchasePriceWithoutTax: Number(avgPurchasePriceWithoutTax).toFixed(2),
            avgPurchasePrice: Number(avgPurchasePrice).toFixed(2),
            nabVreSaPdv: Number(avgPurchasePrice * restQuantity).toFixed(2),
            nabVreBezPdv: Number(avgPurchasePriceWithoutTax * restQuantity).toFixed(2),
            price: Number(price).toFixed(2),
            priceWithoutTax: Number(priceWithoutTax).toFixed(2),
            prodVreBezPdv: Number(priceWithoutTax * restQuantity).toFixed(2),
            prodVreSaPdv: Number(price * restQuantity).toFixed(2),
            exitValueWithoutTax: Number(exitQuantity * avgPurchasePriceWithoutTax).toFixed(2),
            exitValueWithTax: Number(exitQuantity * avgPurchasePrice).toFixed(2)
        };
    });
    return allItems;
});

const initialState = {
    stockList: [],
    loading: false
};

const slice = createSlice({
    name: "stockList",
    initialState,
    extraReducers: {
        [fetchStockList.pending]: (state) => {
            state.loading = true;
        },
        [fetchStockList.fulfilled]: (state, {payload}) => {
            state.stockList = payload;
            state.loading = false;
        },
        [fetchStockList.rejected]: (state, action) => {
            console.error("a", action);
            state.loading = false;
        }
    }
});

// Reducer
export default slice.reducer;

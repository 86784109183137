import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH, dbRef, storageRef } from "../../auth/FirebaseContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { child, get, set } from "firebase/database";
import { sendPasswordResetEmail } from "firebase/auth";
import {
  getSessionTrainingMode,
  isVirtualKeyboardOn,
  setSessionTrainingMode, setSessionVirtualKeyboardOn,
} from "../../helper/session";

export const updateAdvertisementText = createAsyncThunk("updateAdvertisementText", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/others/advertisementText`), data);
});

export const fetchAdvertisementText = createAsyncThunk("updateAdvertisementText", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/others/advertisementText`))).val();
});

export const fetchContactEmails = createAsyncThunk("fetchContactEmails", async () => {
  let emails = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/protected/contactEmail`))).val();
  if (emails)
    return emails.split(",");
  return [];
});

export const updateContactEmails = createAsyncThunk("updateContactEmails", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/protected/contactEmail`), data);
});

export const fetchTIN = createAsyncThunk("fetchTin", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/profile/pub/TIN`))).val();
});

export const fetchSettings = createAsyncThunk("fetchSettings", async () => {
  let settings = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings`))).val();
  let layout = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/layouts`))).val();
  return { settings, layout };
});

export const updateSettings = createAsyncThunk("updateSettings", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings`), data);
});

export const insertUserImage = createAsyncThunk("insertUserImage", async (image) => {
  let imageRef = ref(ref(storageRef, "public/userphotos"), `${AUTH.currentUser.uid}.png`);
  return await uploadBytes(imageRef, image);
});

export const fetchUserLogoUrl = createAsyncThunk("fetchUserLogoUrl", async () => {
  const imageRef = ref(ref(storageRef, "public/userphotos"), `${AUTH.currentUser.uid}.png`);
  return await getDownloadURL(imageRef);
});

export const fetchBankAccount = createAsyncThunk("fetchBankAccount", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/bankAccount`))).val();
});

export const updateBankAccount = createAsyncThunk("updateBankAccount", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/bankAccount`), data);
});

export const fetchAccountant = createAsyncThunk("fetchAccountant", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/accountant`))).val();
});

export const updateAccountant = createAsyncThunk("updateAccountant", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/accountant`),
    {
      email: data.email,
      lastName: data.lastName,
      name: data.name,
      telephone: data.telephone || ""
    });
});

export const requestPasswordReset = createAsyncThunk("requestPasswordReset", async () => {
  return await sendPasswordResetEmail(AUTH, AUTH.currentUser.email);
});

const initialState = {
  tin: undefined,
  isTrainingModActive: getSessionTrainingMode(),
  isVirtualKeyboard: isVirtualKeyboardOn(),
  settings: undefined,
  layout: undefined,
  bankNumber: undefined,
  accountant: undefined,
  userImage: null,
  loading: false,
  updating: false,
  uploading: false,
  advertisementText: undefined,
  printRestaurantSlip: false,
  isAvailableQuantityRequired: false
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    turnOffTrainingMode: (state) => {
      setSessionTrainingMode(false);
      state.isTrainingModActive = false;
    },
    turnOnTrainingMode: (state) => {
      setSessionTrainingMode(true);
      state.isTrainingModActive = true;
    },
    turOnVirtualKeyboard: (state) => {
      setSessionVirtualKeyboardOn(true);
      state.isVirtualKeyboard = true;
    },
    turnOffVirtualKeyboard: (state) => {
      setSessionVirtualKeyboardOn(false);
      state.isVirtualKeyboard = false;
    }
  },
  extraReducers: {
    [fetchUserLogoUrl.pending]: (state) => {
      state.loading = true;
    },
    [fetchUserLogoUrl.fulfilled]: (state, { payload }) => {
      state.userImage = payload;
      state.loading = false;
    },
    [fetchUserLogoUrl.rejected]: (state) => {
      state.userImage = undefined;
      state.loading = false;
    },
    [insertUserImage.pending]: (state) => {
      state.uploading = true;
    },
    [insertUserImage.fulfilled]: (state, { payload }) => {
      state.userImage = payload;
      state.uploading = false;
    },
    [insertUserImage.rejected]: (state) => {
      state.uploading = false;
    },
    [fetchBankAccount.fulfilled]: (state, { payload }) => {
      state.bankNumber = payload;
    },
    [fetchAccountant.pending]: (state) => {
      state.loading = true;
    },
    [fetchAccountant.fulfilled]: (state, { payload }) => {
      state.accountant = payload;
      state.loading = false;
    },
    [fetchAccountant.rejected]: (state) => {
      state.loading = false;
    },
    [updateAccountant.fulfilled]: (state, { meta }) => {
      state.accountant = meta.arg;
      state.loading = false;
    },
    [fetchBankAccount.fulfilled]: (state, { payload }) => {
      state.bankNumber = payload;
      state.loading = false;
    },
    [updateBankAccount.fulfilled]: (state, { meta }) => {
      state.bankNumber = meta.arg;
      state.loading = false;
    },
    // fetchSettings
    [fetchSettings.pending]: (state) => {
      state.loading = true;
    },
    [fetchSettings.fulfilled]: (state, { payload }) => {
      const { settings, layout } = payload;
      state.settings = settings;
      state.layout = layout;
      state.loading = false;
    },
    [fetchSettings.rejected]: (state) => {
      state.loading = false;
    },
    [updateSettings.fulfilled]: (state, { meta }) => {
      state.settings = meta.arg;
    },
    //fetchTIN
    [fetchTIN.fulfilled]: (state, { payload }) => {
      state.tin = payload;
    },
    //fetchAdvertisementText
    [fetchAdvertisementText.fulfilled]: (state, { payload }) => {
      state.advertisementText = payload;
    },
    //updateAdvertisementText
    [updateAdvertisementText.fulfilled]: (state, { meta }) => {
      state.advertisementText = meta.arg;
    }
  }
});

export const { turnOffTrainingMode, turnOnTrainingMode, turOnVirtualKeyboard, turnOffVirtualKeyboard } = slice.actions;
// Reducer
export default slice.reducer;
// routes
import {PATH_DASHBOARD} from "../../../routes/path";
// components
import SvgColor from "../../../components/svg-color";
import { OPERATORS_GROUP } from "../../../constants";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>;


// ----------------------------------------------------------------------

const ICONS = {
    discount: icon("ic_discount"),
    blog: icon("ic_blog"),
    cart: icon("ic_cart"),
    chat: icon("ic_chat"),
    mail: icon("ic_mail"),
    user: icon("ic_user"),
    file: icon("ic_file"),
    lock: icon("ic_lock"),
    label: icon("ic_label"),
    blank: icon("ic_blank"),
    kanban: icon("ic_kanban"),
    folder: icon("ic_folder"),
    banking: icon("ic_banking"),
    booking: icon("ic_booking"),
    invoice: icon("ic_invoice"),
    calendar: icon("ic_calendar"),
    disabled: icon("ic_disabled"),
    external: icon("ic_external"),
    menuItem: icon("ic_menu_item"),
    ecommerce: icon("ic_ecommerce"),
    analytics: icon("ic_analytics"),
    dashboard: icon("ic_dashboard")
};

const navConfig = [
        // ----------------------------------------------------------------------
        {
            subheader: "Početna",
            items: [
                {title: "Početna",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.general.app, icon: ICONS.analytics}
            ]
        },
        {
            subheader: "Kompenzacija",
            items: [
                {title: "Kompenzacija",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.compensation.root, icon: ICONS.ecommerce}
            ]
        },
        {
            subheader: "BAZA",
            items: [
                {
                    title: "Izveštaji",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.reports.root,
                    icon: ICONS.invoice,
                    children: [
                        {title: "Evidencija Prometa", path: PATH_DASHBOARD.reports.overview},
                        {title: "Proizvod", path: PATH_DASHBOARD.reports.item},
                        {title: "Troškovi", path: PATH_DASHBOARD.reports.costs},
                        {title: "Otpis", path: PATH_DASHBOARD.reports.writeOff},
                        {title: "DPU", path: PATH_DASHBOARD.reports.dpu},
                        {title: "Aktivne porudžbine", path: PATH_DASHBOARD.reports.activeOrders},
                        {title: "Izveštaj po filteru", path: PATH_DASHBOARD.reports.filter},
                        {title: "Promet po stolu", path: PATH_DASHBOARD.reports.table},
                        {title: "Promet bez naknade", path: PATH_DASHBOARD.reports.turnoverWithoutCompensation},
                        {title: "Kalkulacije", path: PATH_DASHBOARD.reports.profit},
                        {title: "Kartica proizvoda", path: PATH_DASHBOARD.reports.itemCard},
                        {title: "Reprezentacije", path: PATH_DASHBOARD.reports.representations},
                        {title: "Obrisane porudžbine", path: PATH_DASHBOARD.reports.deletedOrders},
                    ]
                },
                {
                    title: "Proizvodi",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.items.root,
                    icon: ICONS.ecommerce,
                    children: [
                        {title: "Lista proizvoda", path: PATH_DASHBOARD.items.list},
                        {title: "Dodavanje proizvoda", path: PATH_DASHBOARD.items.new},
                        {title: "Početni proizvodi", path: PATH_DASHBOARD.items.starters}
                    ]
                },
                {
                    title: "Troškovi",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.costs.root,
                    icon: ICONS.banking,
                    children: [
                        {title: "Lista troškova", path: PATH_DASHBOARD.costs.list},
                        {title: "Dodavanje troška", path: PATH_DASHBOARD.costs.new}
                    ]
                },
                {
                    title: "Popusti",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.discounts.root,
                    icon: ICONS.discount,
                    children: [
                        {title: "Popusti", path: PATH_DASHBOARD.discounts.list},
                        {title: "Dodavanje popusta", path: PATH_DASHBOARD.discounts.new},
                        {title: "Korisnici popusta", path: PATH_DASHBOARD.discounts.customers},
                        {title: "Dodaj korisnika", path: PATH_DASHBOARD.discounts.customersNew}
                    ]
                },
                {
                    title: "Računi",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.invoices.root,
                    icon: ICONS.invoice,
                    children: [
                        {title: "Lista", path: PATH_DASHBOARD.invoices.list}
                    ]
                },
                {
                    title: "Magacin",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.warehouse.root,
                    icon: ICONS.booking,
                    children: [
                        {title: "Dobavljači", path: PATH_DASHBOARD.warehouse.suppliers},
                        {title: "Fakture", path: PATH_DASHBOARD.warehouse.factures},
                        {title: "Lager lista", path: PATH_DASHBOARD.warehouse.stockList},
                        {title: "Normativ", path: PATH_DASHBOARD.warehouse.ingredients},
                        {title: "Popis", path: PATH_DASHBOARD.warehouse.inventory},
                        {title: "Otpis", path: PATH_DASHBOARD.warehouse.writeOff},
                        {title: "Komitenti", path: PATH_DASHBOARD.warehouse.clients}
                    ]
                },
                {
                    title: "Operateri",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.operators.root,
                    icon: ICONS.user,
                    children: [
                        {title: "Lista operatera", path: PATH_DASHBOARD.operators.list},
                        {title: "Dodavanje operatera", path: PATH_DASHBOARD.operators.new}
                    ]
                },
                {
                    title: "Baza kupaca",
                    roles: [OPERATORS_GROUP.admin,OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                    path: PATH_DASHBOARD.customers.root,
                    icon: ICONS.booking,
                    children: [
                        {title: "Lista kupaca", path: PATH_DASHBOARD.customers.list},
                        {title: "Dodavanje kupaca", path: PATH_DASHBOARD.customers.new}
                    ]
                }
            ]
        }
    ]
;

export default navConfig;
